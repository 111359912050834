/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.ttf');
}

.item-eu-font {
    font-size: 10px!important;
  }

// styles for visualizzatore



// fixes rendering issues for divider

.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
}
.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: dashed !important;
}
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid !important;
}


// same issue in popup menu
body .p-menuitem-separator {
    border-bottom: 1px solid #c7cdd1;
    margin-left: 8px;
    margin-right: 8px;
}

.colorPickerHolder {
    width:             2.5em;
    height:            2.5em;
    border-radius:      4px;
}

// better tooltip display for long strings
.p-tooltip.config_tooltip  {
    white-space: nowrap;
    min-width: 640px;
}

.p-tooltip.projects_list {
    white-space: nowrap;
    min-width:      20em;
}


.textWide {
    width:          30em !important;
}

.textWideLocked {
    width:          30em !important;
    max-width:      30em !important;
    min-width:      30em !important;
}

.textWideLocked ul {
    width:          30em !important;
}


.p-toast-message {
    width: 40em;
    transform: translateX(-25%) !important;
}


.p-fileupload-files {
    white-space: nowrap !important;
}

.formWarning {
    color:          #cc0000;
    font-size:      smaller;
}


// handling extended tabview sizes

.tabWide .p-tabview-nav-content > .p-tabview-nav {
display:flex !important;
}
.tabWide  .p-tabview-nav-content > .p-tabview-nav li{
flex-grow: 1 !important;
}

// forcing full size header
.tabWide.tabHeaderCenter li .p-tabview-nav-link {
    text-align: center;
}
.tabWide.tabHeaderCenter li .p-tabview-title {
    flex-grow: 1 !important;
}


.projectPermissionsRoleSelector {
    min-width:          20em;
}

.p-toast-detail {
    white-space: pre-line;
}
